@use '../../variables';

.housingGallery__thumb {
    margin: 25px auto;
    width: 340px;
    height: 340px;
    border-radius: 10px;
    background: linear-gradient(to top, rgba(0,0,0,0.7), transparent 30%);
    position: relative;

         @media (max-width: calc(variables.$extraLarge-screen - 1px)) {
            margin: 20px;
    }

        @media (max-width: variables.$large-screen) {
            width: 300px;
            height: 300px;
            margin: 20px auto;
    }

        @media (max-width: variables.$medium-screen) {
            width: 400px;
            height: 300px;
    }

            @media (max-width: variables.$small-screen) {
            width: 335px;
            height: 255px;
            margin: 25px auto 0 auto;
    }


        .thumb__image {
            height: 100%;
            width: 100%;
            object-fit:cover;
            border-radius: 10px;
            mix-blend-mode: darken;  
        }

        .thumb__text {
            width: 60%;
            position: absolute;
            left: 20px;
            bottom: 10px;
            color: variables.$secondary-color;
        }
}