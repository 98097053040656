@use '../../variables';

.footer {
    font-family: variables.$primary-font-family;
	font-weight: 300;
	font-size: 1em;
    width: 100%;
    height: 200px;
    margin: 50px auto 0 auto;
    background-color: black;
    color: variables.$secondary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: variables.$small-screen) {

        p {
           width: 40%;
           line-height: 1.5;
           text-align:justify;
       }
   }
}