@use '../../variables';

.Housing__introduction {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: variables.$medium-screen) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .Introduction__rentalPresentation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: variables.$primary-color;

        @media (max-width: variables.$small-screen) {
            margin: 0 0 15px 0;
        }

        .Introduction__title {
            margin: 0 0 15px 0;
            font-weight: 500;
            font-size: 2em;

            @media (max-width: variables.$medium-screen) {
                font-size: 1.75em;
            }

            @media (max-width: variables.$small-screen) {
                font-size: 1.5em;
            }

        }

        .Introduction__location {
            margin: 0 0 20px 0;
        }
    }

    .Introduction__ownerPresentation {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width: variables.$medium-screen) {
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
        }

        .Introduction__ownerContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: variables.$primary-color;

            .Introduction__ownerContainer,
            img {
                border-radius: 75px;
                width: 65px;
                height: 65px;
                margin: 0 0 0 20px;
            }

        }
    }
}