@use '../../variables';

.Introduction__tagsContainer {
    display: flex;

    .Introduction__tags {
        margin: 0 10px 0px 0;
        width: auto;
        padding: 5px 40px;
        border-radius: 10px;
        background-color: variables.$primary-color;
        color: variables.$secondary-color;
        text-align: center;
        align-self: center;
        font-size: 1em;

        @media (max-width: variables.$large-screen) {
            padding: 5px 30px;
        }

        @media (max-width: variables.$medium-screen) {
            padding: 5px 20px;
        }

        @media (max-width: variables.$small-screen) {
            padding: 5px 5px;
            font-size: 0.8em;
        }
    }
}