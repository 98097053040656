@use '../../variables';

.header {
    font-family: variables.$primary-font-family;
    max-width: 1240px;
    margin: 50px auto 25px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: variables.$extraLarge-screen) {
        width: 90%;
        margin: 25px auto;
    }

    img {
        width: 100%;
        height: auto;
    }

    &__link {
        margin: 0 0 0 55px;
        color: variables.$primary-color;
        text-decoration: none;
        font-size: 1.5em;
        font-weight: 500;

        &.active {
            text-decoration: underline;
        }

        @media (max-width: variables.$small-screen) {
            font-size: 1em;
            margin: 0 0 0 20px;
            text-transform: uppercase;
        }
    }

    @media (max-width: variables.$small-screen) {

        &__logo {
            width: 30%;
        }

        nav {
            width: 60%;
            display: flex;
            justify-content: flex-end;
        }
    }
}