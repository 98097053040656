@use '../../variables';

.pagination {
    color: variables.$primary-color;
    display: flex;
    justify-content: space-evenly;
    align-items:center;
    list-style-type: none;

    @media (max-width: variables.$small-screen) {
        justify-content: space-around;
    }


    > li {
        height: 10px;
        position: relative;

        .pagination__Button {
            background: transparent;
            color: variables.$primary-color;
            border: none;
            cursor: pointer;

            &.active {
                color:black;
            }

            &.navigatation {
                width: 10px;
                height: 10px;
                border-top: 2px solid variables.$primary-color;
                border-right: 2px solid variables.$primary-color;

                &.previous {
                    position: absolute;
                    top: 50%;
                    transform: rotate(225deg);
                }
    
                &.next{
                    position: absolute;
                    top: 50%;
                    transform: rotate(45deg);
                }
            }
        }
    }
}