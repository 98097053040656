@use '../../variables';

.Introduction__notationContainer {
    margin: 20px 0 0 0;
    width: auto;
    display: flex;
    justify-content: flex-end;

    @media (max-width: variables.$small-screen) {
        margin: auto 0;
        justify-content: flex-start;
    }

    .Introduction__notationStar {
        width: 15px;
        height: 15px;
        margin: 0 2px;
    }
}