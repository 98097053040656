@use '../../variables';

.dropdown {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #F6F6F6;
    border-radius: 10px;

    @media (max-width: variables.$small-screen) {
        margin: 20px 0 0 0;
    }

    

    &__header {
        height: 25px;
        padding: 10px 20px 10px 10px;
        border-radius: 10px;
        background-color: variables.$primary-color;
        color:variables.$secondary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dropdown__button {
            width: 15px;
            height: 15px;
            background-color: transparent;
            border-top: 3px solid variables.$secondary-color;
            border-right: 3px solid variables.$secondary-color;
            border-bottom: none;
            border-left: none;
            cursor: pointer;
            transition: transform 500ms ease-in;
    
                &.close {
                    transform: rotate(315deg);
                }
    
                &.open {
                    transform: rotate(135deg);
                }
        }

    }

    &__content {
        color: variables.$primary-color;
        width: auto ;
        padding: 0px 20px;
        height: 0px;
        overflow: hidden;
        display: flex;
        align-items: center;
        transition: height 500ms ease-in;
    
        > ul {
            list-style-type: none;
            
            > li {
                margin: 10px 0;
            }    
        }
    
        > p {
            padding: 10px 0px;
            line-height: 1.5;
        }
    }
}