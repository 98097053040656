@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

$primary-color: #FF6060;
$secondary-color: white;

$small-screen : 640px;
$medium-screen : 768px;
$large-screen : 1024px;
$extraLarge-screen : 1240px;

$primary-font-family : 'Montserrat';