@use '../../variables';
.About__container {
        width: 82.5%;
        margin: auto;

        @media (max-width: variables.$large-screen) {
                width: 90%;
        }


        @media (max-width: variables.$medium-screen) {
                width: 95%;
                margin: 0 auto 30px auto;
        }

        @media (max-width: variables.$small-screen) {
                width: 100%;
                margin: 0 0 30px 0;
        }
}