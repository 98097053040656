@use '../../variables';

.carousel {
    margin: 25px 0;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

        @media (max-width: variables.$large-screen) {
            width: auto;
            height: 350px;
    }

        @media (max-width: variables.$medium-screen) {
            width: auto;
            height: 300px;
    }

        @media (max-width: variables.$small-screen) {
            height: 225px;
    }

    > img {
        position: absolute;
        z-index: 1;
        border-radius: 25px;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__buttonContainer {
        position: absolute;
        z-index: 2;
        width: 95%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .carousel__navigateButton {
            background: transparent;
            width: 40px;
            height: 40px;
            border-top: 5px solid variables.$secondary-color;
            border-right: 5px solid variables.$secondary-color;
            border-left: none;
            border-bottom: none;

                &.previous {
                    transform: rotate(225deg);
                }

                &.next{
                    transform: rotate(45deg);
                }

                @media (max-width: variables.$small-screen) {
                    width: 20px;
                    height: 20px;
                    border-top: 3px solid variables.$secondary-color;
                    border-right: 3px solid variables.$secondary-color;
                }
        }
    }

    &__imageCounter {
        position: absolute;
        z-index: 2;
        bottom: 10px;
        color: variables.$secondary-color;
        
        @media (max-width: variables.$medium-screen) {
            display: none;
        }
    }

    .inactive {
        display: none;
    }
}