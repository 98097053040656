@use '../../variables';

.Housing__dropwdownContainer {
    display: flex;
    justify-content: space-between;
    
    @media (max-width: variables.$medium-screen) {
        flex-direction: column;
        margin: 0 0 30px 0;
    }

    > div {
        width: 47%;

        @media (max-width: variables.$medium-screen) {
            width: 100%;
        }
    }
}